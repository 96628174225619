var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState, useEffect, useRef } from "react";
import useThrottleFn from "./useThrottleFn";
var valueComparison = function (left, right) { return !(left === right); };
/**
 * useThrottle
 *
 * @param value
 * @param wait number @default 0
 * @param options object
 */
export default function useThrottle(value, wait, options) {
    if (wait === void 0) { wait = 0; }
    var _a = options || {}, customizer = _a.customizer, restOptions = __rest(_a, ["customizer"]);
    var notEqual = typeof customizer === "function" ? customizer : valueComparison;
    var prevValue = useRef(value);
    var _b = useState(value), state = _b[0], setState = _b[1];
    var _c = useThrottleFn(function (v) {
        setState(v);
    }, wait, restOptions), callback = _c.callback, cancel = _c.cancel, callPending = _c.callPending;
    useEffect(function () {
        if (notEqual(prevValue.current, value)) {
            callback(value);
            prevValue.current = value;
        }
    }, [callback, notEqual, value]);
    return [state, { cancel: cancel, callPending: callPending }];
}
