import { useCallback, useRef, useEffect } from "react";
/**
 * useThrottleFn
 *
 * @param fn function
 * @param wait number @default 0
 * @param options object
 */
export default function useThrottleFn(fn, wait, options) {
    if (wait === void 0) { wait = 0; }
    var timer = useRef();
    var fnRef = useRef(fn);
    var optionsRef = useRef(options);
    var currentArgs = useRef();
    fnRef.current = fn;
    optionsRef.current = options;
    var cancel = useCallback(function () {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = undefined;
    }, []);
    var callback = useCallback(function () {
        var _a;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        currentArgs.current = args;
        if (!timer.current) {
            if ((_a = optionsRef.current) === null || _a === void 0 ? void 0 : _a.leading) {
                fnRef.current.apply(fnRef, currentArgs.current);
                timer.current = setTimeout(function () {
                    timer.current = undefined;
                }, wait);
            }
            else {
                timer.current = setTimeout(function () {
                    fnRef.current.apply(fnRef, currentArgs.current);
                    timer.current = undefined;
                }, wait);
            }
        }
    }, [wait]);
    var callPending = useCallback(function () {
        if (!timer.current) {
            return;
        }
        fnRef.current.apply(fnRef, currentArgs.current);
        cancel();
    }, [cancel]);
    useEffect(function () { return cancel; }, [cancel]);
    return {
        callback: callback,
        cancel: cancel,
        callPending: callPending,
    };
}
